import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
const queryString = require("query-string");

const Redirect = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    console.log(parsed);
  }, [parsed]);

  return (
    <div className="page-content">
      <div className="container container2">
        <section>
          <div
            className={
              parsed.responsecode === "0" ? "trust success" : "trust error"
            }
          >
            <h1>
              {parsed.responsecode === "0"
                ? "Votre paiement a été effectué avec succès"
                : "Votre paiement a échoué"}
            </h1>
            <p>
              {parsed.responsecode === "0"
                ? "Cliquez sur le bouton en bas afin de rejoindre notre groupe WhatsApp"
                : "S'il s'agit d'une erreur, veuillez nous laisser un mail à l'adresse mail ci-dessous."}
            </p>
            <input
              type="button"
              value={
                parsed.responsecode === "0"
                  ? "Rejoindre le groupe whatsapp"
                  : "info@sc-digital.org"
              }
              className="btn"
              onClick={() =>
                window.open(
                  parsed.responsecode === "0"
                    ? "https://chat.whatsapp.com/DgauCrVQOfwKfabbBFsSje"
                    : "mailto:info@sc-digital.org",
                  "_blank"
                )
              }
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Redirect;

import { useEffect, useState } from "react";

function CountdownTimer() {
  const [expiryTime, setExpiryTime] = useState("01 Decembre 2022 00:00:00");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownlMinutes: "",
    countdownSeconds: ""
  });

  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  };

  useEffect(() => {
    countdownTimer();
  });

  return (
    <div className="row">
      {expiryTime !== false ? (
        <>
          <button type="button" className="btn border">
            {countdownTime.countdownDays} <sub>Jous</sub>
          </button>
          <span>:</span>
          <button type="button" className="btn  border">
            {countdownTime.countdownHours} <sub>Heures</sub>
          </button>
          <span>:</span>
          <button type="button" className="btn border">
            {countdownTime.countdownMinutes} <sub>Minutes</sub>
          </button>
          <span>:</span>
          <button type="button" className="btn  border">
            {countdownTime.countdownSeconds} <sub>Secondes</sub>
          </button>
        </>
      ) : null}
    </div>
  );
}
export default CountdownTimer;

import logo from "../assets/images/logo.png";
import menu from "../assets/images/menu.png";
import play from "../assets/images/play.png";
import close from "../assets/images/close.png";
import trust from "../assets/images/trust.png";
import visual from "../assets/images/visual.jpg";
import sekou from "../assets/images/sekou.jpg";

const Images = {
  logo,
  menu,
  visual,
  close,
  sekou,
  trust,
  play
};
export default Images;

class PaiementPro {
  constructor(merchantId) {
    this.merchantId = merchantId;
    this.amount = 0; /* Montant a payer */
    this.description = ''; /* Description pour le paiement obligatoire */
    this.channel =
      ''; /* Mode paiment consulter l'espace paiment pour les different provider */
    this.countryCurrencyCode = '952'; /* Code de la devise: FCFA par default */
    this.referenceNumber =
      ''; /* Reference de la transaction obligatoire et unique */
    this.customerEmail = ''; /* Email de l'utilisateur obligatoire */
    this.customerFirstName = ''; /* Nom de l'utilisateur obligatoire */
    this.customerLastname = ''; /* Prénoms de l'utilisateur obligatoire */
    this.customerPhoneNumber = ''; /* Contact de l'utilisateur obligatoire */
    this.notificationURL =
      ''; /* URL de notication dans le cas ou vous enregistrer les donnée sur votre espace */
    this.returnURL =
      ''; /* URL de retour après paiement: Il es conseiller d'utiliser le même que notificationURL  */
    this.returnContext =
      ''; /* Donnée prensent dans returnURL Ex: {utilisateur_id:1,data:true}  */
    this.url = ''; /* Url de paiement */
    this.success = false; /* initialisation du paiement  */
  }
  async init() {
    const response = await fetch(
      'https://www.paiementpro.net/webservice/onlinepayment/js/initialize/initialize.php',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this),
      },
    );
    let data = await response.json().then(data => {
      this.url = data.url;
      this.success = data.success;
    });
  }
  async getUrlPayment() {
    let data = await this.init();
  }
}

export {PaiementPro};

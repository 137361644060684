import React, { useState, useEffect } from "react";
import Images from "../constantes/images";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";
import Reservation from "../components/Reservation";

const Nav = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 250) {
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    };
  }, []);

  return (
    <nav className={"navbar"}>
      <NavLink to="/" className="nav-logo">
        <img src={Images.logo} alt="logo" />
      </NavLink>
      <li className="info">
        Créer et développer son business grâce à Facebook
      </li>
      {display ? (
        <Popup
          closeOnDocumentClick={false}
          modal
          trigger={
            <input
              type="button"
              value={"Réservez votre place"}
              className="btn"
            />
          }
          nested
        >
          {(close) => <Reservation close={close} />}
        </Popup>
      ) : (
        <span></span>
      )}
    </nav>
  );
};
export default Nav;

import React, { useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
import { PaiementPro } from "../PaimentPro/PaiementPro";

const Reservation = ({ close }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);

  async function handleBuy(e) {
    e.preventDefault();
    setPending(true);
    let transactionId =
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36);
    let paiementPro = new PaiementPro("PP-F496");
    paiementPro.amount = 36225;
    paiementPro.channel = [
      "CARD",
      "OMCIV2",
      "MOMOCI",
      "WAVECI",
      "AIRTELNG",
      "FLOOZBJ",
      "MOMOBJ",
      "OMML",
      "OMBF",
      "FLOOZ"
    ];
    paiementPro.referenceNumber = "Formation.3.Décembre." + transactionId;
    paiementPro.customerEmail = email;
    paiementPro.customerLastname = name;
    paiementPro.customerPhoneNumber = phone;
    paiementPro.description =
      "Créer et développer son business grâce à Facebook";
    paiementPro.notificationURL = `https://formation.sc-digital.org/redirect`;
    paiementPro.returnURL = `https://formation.sc-digital.org/redirect`;
    try {
      await paiementPro.getUrlPayment();
      if (paiementPro.success) {
        setPending(false);
        window.open(paiementPro.url, "_blank");
        setName("");
        setEmail("");
        setPhone("");
        close();
      } else {
        setPending(false);
        setError(
          "Une erreur s'est produite avec l'initialisation du paiement. Réessayer svp!"
        );
        setTimeout(() => {
          setError(null);
        }, 4000);
      }
    } catch (error) {
      setPending(false);
      setError(
        "Une erreur s'est produite avec l'initialisation du paiement. Réessayer svp!"
      );
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }

  return (
    <form onSubmit={handleBuy} className="login-form">
      <h2>Ajouter vos informations</h2>

      <label htmlFor="">
        <span>Nom & Prénom(s) : </span>{" "}
        <input
          type="text"
          name="name"
          className="val-input"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
      </label>
      <label htmlFor="">
        <span>Email : </span>{" "}
        <input
          type="email"
          name="email"
          className="val-input"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </label>
      <label htmlFor="">
        <span>Numéro : </span>{" "}
        <input
          type="text"
          name="phone"
          className="val-input"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          required
        />
      </label>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <input
          value={"Annuler"}
          className={"btn bgRed"}
          onClick={() => close()}
          style={{ width: "50%", margin: "6px", color: "white" }}
        />

        {pending ? (
          <Spinner color={`var(--first-color)`} />
        ) : (
          <input
            type="submit"
            value={"Réserver"}
            className={"btn"}
            style={{ width: "50%", margin: "6px" }}
          />
        )}
      </div>
      {error && <span className="error">{error}</span>}
      <span className="info">
        Vous allez être redirigé vers la page de paiement après avoir rempli et
        cliquer sur le buton "Réserver".
      </span>
    </form>
  );
};

export default Reservation;

import React from "react";
import ReactPlayer from "react-player";
import Images from "../constantes/images";
import Popup from "reactjs-popup";
import Reservation from "../components/Reservation";
import CountdownTimer from "../components/CountdownTimer";

const Home = () => {
  return (
    <div className="page-content">
      {/*Header*/}
      <div className="header">
        <div className="container">
          <section>
            <div className="media">
              <ReactPlayer
                url="https://youtu.be/JS0Bituwf00"
                controls
                light
                playIcon={
                  <img src={Images.play} alt="play btn" className="play-btn" />
                }
              />
            </div>
            <div className="description">
              {" "}
              <h1>
                Découvrez mes meilleurs astuces dans ma formation Facebook{" "}
              </h1>
              <div>
                <strong>Une méthode simple pour</strong>
                <p className="white li">
                  <span class="dot">•</span> Identifier des prospects ciblés
                </p>
                <p className="white li">
                  <span class="dot">•</span> Transformer un ami Facebook en
                  client
                </p>
                <p className="white li">
                  <span class="dot">•</span> Réaliser des ventes
                </p>
                <p className="white li">
                  <span class="dot">•</span> Automatiser tout votre système de
                  vente
                </p>
              </div>
              <p className="li">7h de formation en présentielle pour </p>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingVertical: "4"
                }}
              >
                <span className="btn nocursor">
                  {process.env.REACT_APP_PRICE} FCFA
                </span>
                <span className="nocursor off">105 000 FCFA</span>
              </p>
              <p className="nocursor">
                <span>Valable du 08 Novembre au 30 Novembre</span>
              </p>
              <p>
                <CountdownTimer />
              </p>
              <Popup
                closeOnDocumentClick={false}
                modal
                lockScroll
                trigger={
                  <input
                    type="button"
                    value={"Je réserve ma place maintenant"}
                    className="btn"
                  />
                }
                nested
                position="center"
              >
                {(close) => <Reservation close={close} />}
              </Popup>
            </div>
          </section>
        </div>
      </div>

      {/* Visual */}
      <div className="container container2">
        <section className="row-reverse">
          <div className="media">
            <img
              data-testid="media"
              src={Images.visual}
              alt=""
              className="visual-image"
            />
          </div>
          <div className="description">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingVertical: "4"
              }}
            >
              <span className="btn nocursor">
                {process.env.REACT_APP_PRICE} FCFA
              </span>
              <span className="nocursor off">105 000 FCFA</span>
            </p>

            <h2>Prospectez avec Facebook </h2>
            <div>
              <p className="black">
                Vous allez découvrir{" "}
                <strong>
                  mes meilleurs conseils et astuces pour trouver des clients sur
                  Facebook
                </strong>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
      {/* Formateur */}
      <div className="container container2">
        <section className="row-reverse">
          <div className="media">
            <img
              data-testid="media"
              src={Images.sekou}
              alt=""
              className="visual-image"
            />
          </div>
          <div className="description">
            <h2>Qu'allez vous obtenir dans la formation ? </h2>
            <div>
              <p className="black">
                J'ai développé cette formation pour les indépendants, les
                consultants, les petites entreprises, les marketeurs... qui
                veulent trouver de nouvelles façons d'obtenir des clients et de
                vendre grâce à <strong>Facebook</strong>.{" "}
                <strong>Je vous explique étape par étape comment</strong> :
              </p>
              <p className="black li">
                <span class="dot">•</span> Puiser dans le pool de prospects de
                Facebook sans passer des heures
              </p>
              <p className="black li">
                <span class="dot">•</span> Capter l'attention de vos prospects
                et les inciter à vous contacter
              </p>
              <p className="black li">
                <span class="dot">•</span> Exploiter à 100% votre liste d'amis
                Facebook afin d`obtenir des rendez-vous
              </p>
              <p className="black li">
                <span class="dot">•</span> Devenir une référence dans votre
                domaine et rester dans l’esprit de vos prospects afin qu’ils
                deviennent vos clients
              </p>
              <p className="black li">
                <span class="dot">•</span> Automatiser vos ventes grâce à la
                publicité Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> Se servir de la concurrence pour
                développer votre activité rapidement
              </p>
              <p className="black li">
                <span class="dot">•</span> Amener les clients à payer en ligne
                au lieu de paiement à la livraison
              </p>
            </div>
            <p className="black">
              Vous allez économiser BEAUCOUP de temps car vous profiterez de mes
              nombreux essais et tests pour ne pas vous perdre en actions
              inutiles et aller à l`essentiel !
            </p>
          </div>
        </section>
      </div>

      {/* Temoin */}
      <div className="container container2">
        <h2>Voici les retours de mes premiers étudiants </h2>
      </div>
      <div className="grid">
        <div data-aspect="16:9" className="card">
          <ReactPlayer
            url="https://youtu.be/wr2N57Ot2yU"
            controls
            light
            playIcon={
              <img src={Images.play} alt="play btn" className="play-btn" />
            }
            width="100%"
            height="100%"
          />
        </div>
        <div data-aspect="16:9" className="card">
          {" "}
          <ReactPlayer
            url="https://youtu.be/x7gPZ7pbmUY"
            controls
            light
            playIcon={
              <img src={Images.play} alt="play btn" className="play-btn" />
            }
            width="100%"
            height="100%"
          />
        </div>
        <div data-aspect="16:9" className="card">
          <ReactPlayer
            url="https://youtu.be/kN9b7nEZtJU"
            controls
            light
            playIcon={
              <img src={Images.play} alt="play btn" className="play-btn" />
            }
            width="100%"
            height="100%"
          />
        </div>
        <div data-aspect="16:9" className="card">
          <ReactPlayer
            url="https://youtu.be/8gYlnihcx9k"
            controls
            light
            playIcon={
              <img src={Images.play} alt="play btn" className="play-btn" />
            }
            width="100%"
            height="100%"
          />
        </div>
      </div>
      {/* Module */}
      <div className="container container2">
        <section>
          <div className="description">
            <h2>Les modules de la formation</h2>
            <div>
              <p>
                Module 1 -{" "}
                <b>TRANSFORMER SON PROFIL FACEBOOK EN MACHINE À VENDRE</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> La méthode pour prospecter ses futurs
                client sur Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> Le secret de la visibilité sur
                Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> La technique secrète pour surpasser
                vos concurrents
              </p>
              <p className="black li">
                <span class="dot">•</span> Mes stratégies pour surmonter le
                syndrome de l’imposteur
              </p>
              <p className="black li">
                <span class="dot">•</span> Que faire lorsque vos concurrents
                vous attaque?
              </p>
            </div>
            <div>
              <p>
                Module 2 - <b>NOUVELLE VERSION DES PAGES FACEBOOK</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> Avantages et inconvénients
              </p>
              <p className="black li">
                <span class="dot">•</span> Augmenter le nombre d’abonnés de sa
                page légalement
              </p>
            </div>
            <div>
              <p>
                Module 3 - <b>BOOSTER VOS PUBLICATIONS FACEBOOK</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> 07 étapes pour booster une
                publication Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> Avantages du bouton booste
              </p>
              <p className="black li">
                <span class="dot">•</span> Inconvénients du bouton boost
              </p>
            </div>
            <div>
              <p>
                Module 4 - <b>SPONSORING DES PAGES FACEBOOK DE A à Z</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> Comprendre le Business Manager de
                Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> La stratégie de filtre des audiences
              </p>
              <p className="black li">
                <span class="dot">•</span> La puissance du pixel Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> Lancer une publicité conversation via
                Messenger/WhatsApp
              </p>
            </div>
            <div>
              <p>
                Module 5 -{" "}
                <b>03 TECHNIQUES POUR OBTENIR UNE AUDIENCE DE QUALITÉ</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> Les stratégies de la segmentation des
                tranches d'âge{" "}
              </p>
              <p className="black li">
                <span class="dot">•</span> Ma méthode idéale pour trouver la
                meilleure audience
              </p>
              <p className="black li">
                <span class="dot">•</span> Associer pixel Facebook + site
                internet et devenez incontournable
              </p>
            </div>
            <div>
              <p>
                Module 6 - <b>MA PAGE FACEBOOK SC DIGITAL SUPPRIME</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> Éviter mes erreurs de débutant
              </p>
              <p className="black li">
                <span class="dot">•</span> 09 erreurs qui entraînent la
                suppression de vos pages Facebook
              </p>
              <p className="black li">
                <span class="dot">•</span> Ce que j’aurais dû savoir en 2019
                avant la vente de mes produits et services
              </p>
            </div>
            <div>
              <p>
                Module 7 - <b>05 TECHNIQUES POUR MIEUX VENDRE AVEC DES MOTS</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> Les bases du Copywritting
              </p>
              <p className="black li">
                <span class="dot">•</span> Captiver le lecteur avec un titre
                percutant
              </p>
              <p className="black li">
                <span class="dot">•</span> Transformer les caractéristiques de
                vos produits ou services en bénefice
              </p>
            </div>
            <div>
              <p>
                <b>BONUS</b>
              </p>
              <p className="black li">
                <span class="dot">•</span> FORMATION VIDÉO APPRENDRE À CRÉER SON
                SITE INTERNET AVEC WORDPRESS
              </p>
              <p className="black li">
                <span class="dot">•</span> FORMATION VIDÉO PRATIQUE
                &#x2039;&#x2039; ACHETER EN CHINE SANS SE DÉPLACER GRACE À SON
                SMARTPHONE &#x203A;&#x203A;
              </p>
              <p className="black li">
                <span class="dot">•</span> FORMATION PRATIQUE CRÉATION DE VISUEL
                CAPTIVANT AVEC CANVA
              </p>
              <p className="black li">
                <span class="dot">•</span> LICENCE À VIE DE CANVA OFFERTE
              </p>
              <p className="black li">
                <span class="dot">•</span> ADHÉSION AU PROGRAMME MONEY RAPID
              </p>
            </div>
          </div>
        </section>
      </div>
      {/* trust */}
      <div className="container container2">
        <section>
          <div className="trust">
            <h2>100% satisfait ou 100% remboursé</h2>
            <p>
              Avec moi vous êtes tranquille : ma formation est accompagnée d'une
              garantie 100% Satisfait ou 100% remboursé durant 30 jours sans
              aucune condition !{" "}
            </p>
            <p>
              Essayez "Créér et developper son business grâce à Facebook"
              pendant 30 jours, si vous n’êtes pas satisfait , envoyez moi un
              email à{" "}
              <a href="mailto:sekou@sc-digital.org">sekou@sc-digital.org</a> et
              vous serez automatiquement remboursé, sans condition.
            </p>
            <p>
              Mais en toute honnêteté, j'ai mis tout mon savoir et toute mon
              expérience dans cette formation donc je doute que vous demanderez
              ce remboursement.
            </p>
            <img src={Images.trust} alt="trust" />
          </div>
        </section>
      </div>
      {/* Faq */}
      <div className="container container2">
        <section>
          {" "}
          <div className="description">
            <h2>Vous avez encore des questions ? (FAQ)</h2>
            <div>
              <h3>Si je ne suis pas satisfait, que se passe-t-il ?</h3>
              <p>
                Vous bénéficiez de 30 jours d'essais 100% satisfait ou 100%
                remboursé ! Vous n'aurez aucune justification à faire, vous
                serez intégralement remboursé. Ce qui fait que l'achat de cette
                formation est un achat à RISQUE ZERO pour vous ! Essayez cette
                formation et vous pourrez juger de sa qualité sans risque.
              </p>
            </div>
            <div>
              <h3>Je suis une entreprise, est ce que c'est pour moi ?</h3>
              <p>
                Cette formation s'adresse aussi bien aux indépendants qu'aux
                entreprises qui souhaitent générer des Prospects sur Facebook
                avec leur équipe de commerciaux. Bien evidement vous recevez une
                facture de la part de ma startup SC DIGITAL pour que vous
                puissez deduire les depenses
              </p>
            </div>
            <div>
              <h3>Si j'ai besoin d'aide comment je fais ?</h3>
              <p>
                Vous avez le Groupe Privé sur Facebook où je réponds, ainsi que
                les autres clients de la formation. Généralement les réponses
                sont fournies en moins de 24H.
              </p>
            </div>
            <div>
              <h3>Je suis étudiant, est ce que j'ai un prix ?</h3>
              <p>
                Oui, vous avez 50% de réduction sur le prix public, il suffit de
                m'envoyer un email à sekou@sc-digital.org avec une copie de
                votre carte d'étudiant
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
